import React, { useState, useEffect } from 'react';
import {
  Button,
  ButtonGroup,
  Typography,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from '@one-thd/sui-atomic-components';
import { shouldDisplay, handleRedirect, handleDecline } from '../utils';

const DiscountEmailEntry = () => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('discount_email_entry.ready'); }, []);

  const [open, setOpen] = useState(shouldDisplay());

  const handleClose = () => {
    handleDecline(90);
    setOpen(false);
  };

  const MODAL_TEXT = {
    HEADER: 'Get Up to $20 Off Your First In-Store Purchase*',
    SUBHEADER: 'Create an Account and Subscribe to The Home Depot Special Offers.',
    BODY: `* Receive $20 off any order of $200 or more by creating a Pro Xtra account and 
    subscribing to receive special email offers. Receive $5 off any order of $50 or more by
     creating a personal account and subscribing to receive special email offers. Offer only 
     available to new customers that do not already receive email offers and do not have an 
     account with The Home Depot. `
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <ModalHeader onClose={handleClose}> {MODAL_TEXT.HEADER} </ModalHeader>
      <ModalBody className="sui-overflow-hidden">
        <div className="sui-pb-4">
          <Typography variant="body-lg"> {MODAL_TEXT.SUBHEADER} </Typography>
        </div>
        <Typography variant="body-xs"> {MODAL_TEXT.BODY} </Typography>
      </ModalBody>
      <ModalFooter>
        <ButtonGroup orientation="vertical" variant="primary">
          <Button onClick={handleRedirect} variant="primary">Get My Discount</Button>
          <Button onClick={handleClose} variant="secondary">No Thanks</Button>
        </ButtonGroup>
      </ModalFooter>
    </Modal>
  );

};

DiscountEmailEntry.propTypes = {};

DiscountEmailEntry.defaultProps = {};

DiscountEmailEntry.displayName = 'DiscountEmailEntry';

export { DiscountEmailEntry };