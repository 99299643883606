/* global digitalData */
import THDCustomer from '@thd-olt-global/thd-customer';
import Cookies from 'js-cookie';

const isNativeAppFromCookie = () => {
  const encodedNative = 'e1%3ds1';
  const cookie = document.cookie.toLowerCase();
  return (
    (cookie
      && cookie.indexOf('thd_online_channel') > -1
      && cookie.indexOf(encodedNative) > -1)
  );
};

export const isNativeApp = () => {
  try {
    const channelType = (document.getElementById('channel_type') == null)
      ? 'desktop'
      : document.getElementById('channel_type').value;
    return ((channelType.toUpperCase() === 'MOBILEAPP') || false);
  } catch (error) {
    console.error('napp channel detection exception', error);
    return isNativeAppFromCookie();
  }
};

function eventReady(obj) {
  if (!window.digitalData) {
    return;
  }
  digitalData.event = digitalData.event || [];
  window.digitalData.event.push(obj);
  if (
    typeof localStorage !== 'undefined'
    && localStorage.getItem('AnalyticsDebug')
  ) {
    console.log('[Analytics event] ', obj);
  }
}

function onDisplayAnalytics() {
  try {
    const event = {
      category: {
        primaryCategory: 'overlay'
      },
      eventInfo: {
        eventName: 'create account and subscribe promo offer'
      }

    };
    eventReady(event);
  } catch (error) {
    console.error('Analytics failed: ', error);
  }
}

function onDeclineAnalytics() {
  try {
    const event = {
      category: {
        primaryCategory: 'button submission'
      },
      eventInfo: {
        eventName: 'my account settings'
      },
      myAccount: {
        settings: 'email interrupter: no thanks'
      }
    };
    eventReady(event);
  } catch (error) {
    console.error('Analytics failed: ', error);
  }
}

// determine if component should be displayed
export const shouldDisplay = () => {
  if (typeof window === 'undefined') return false;

  const { isLoggedIn } = THDCustomer;
  const isOpen = !(isLoggedIn || Cookies.get('emailPromoDeclined') || isNativeApp());

  if (isOpen) {
    onDisplayAnalytics();
  }
  return isOpen;
};

export const handleDecline = (days) => {
  Cookies.set('emailPromoDeclined', 'true', { expires: days });
  onDeclineAnalytics();
};

// set session storage flags for discount and create account / sign in trigger then redirect
export const handleRedirect = () => {
  sessionStorage.setItem('interrupterDiscount', true);
  window.location.href = '/auth/view/signin';
};
